import orderBy from 'lodash/orderBy'

import { adminUri, apiUri, appName, appVersion, olarkId } from '@fv/client-core'
import { type LoadDocumentType, loadDocumentTypeConfigs } from '@fv/models'

export { adminUri, apiUri, appName, appVersion, olarkId }
export const defaultPageSize = 20
export const siteTitle = 'Freightview Carrier'
export const supportMessage = 'please contact support if the issue persists.'

type SelectableDocumentType = LoadDocumentType | ''
const carrierOptions = orderBy([...loadDocumentTypeConfigs], [i => i.name], [])
  .filter(c => c.allowedSources.includes('carrier'))
  .map(({ key, name }) => ({
    text: name,
    value: key,
  }))
export const documentTypes: Array<{
  text: string
  value: SelectableDocumentType
}> = [{ text: 'Document type', value: '' }, ...carrierOptions]
